/* eslint no-console: 0 */

import Sample from '../components/sample.vue'
import store from 'store'
import Cookies from 'js-cookie'

import Plotly from 'plotly.js-dist';

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#home-app',
    data: {
      error: null,
      race: {},
      time_limit: null,
      date_limit: 4,
      race_kind: null,
      race_type: null,
      race_date: null,
      race_track: null,
      race_no: null,
      race_horse: null,
      jra_race_date: null,
      jra_date_info: {},
      jra_track_info: {},
      jra_races: [],

      nar_race_date: null,
      nar_date_info: {},
      nar_track_info: {},
      nar_races: [],

      br_race_date: null,
      br_date_info: {},
      br_track_info: {},
      br_races: [],

      kr_race_date: null,
      kr_date_info: {},
      kr_track_info: {},
      kr_races: [],

      page: 1,
      limit: 2,
      total_count: 0,
      fullscreenLoading: false,
      timePopOddsIdx: 0,
      sortedPops: [],
      displaySetting: true,
      isSectionsIndeterminate: false,
      checkSectionsAll: true,
      sectionTypes: [
        {'key':'odds','value':'odds','label':'人気順オッズ'},
        {'key':'umabetsu','value':'umabetsu','label':'時系列オッズ'},
        {'key':'std_graph','value':'std_graph','label':'偏差グラフ'},
        {'key':'time_graph','value':'time_graph','label':'時系列グラフ'},
        {'key':'prize','value':'prize','label':'払い戻し情報'}
      ],
      displaySections: ['odds', 'umabetsu', 'std_graph', 'time_graph', 'prize'],
      isLinesIndeterminate: false,
      checkLinesAll: true,
      lineTypes: [
        {'key':'odds','value':'odds','label':'オッズ'},
        {'key':'rate','value':'rate','label':'得票率'},
        {'key':'rate_diff','value':'rate_diff','label':'得票率差'},
        {'key':'pop','value':'pop','label':'人気順位'}
      ],
      displayLines: ['odds', 'rate', 'rate_diff', 'pop'],
      isSikisIndeterminate: false,
      checkSikisAll: true,
      sikiTypes: [
        {'key':'tanpuku','value':'tanpuku','label':'単勝'},
        {'key':'huku','value':'huku','label':'複勝'},
        {'key':'wide','value':'wide','label':'ワイド'},
        {'key':'wakuhuku','value':'wakuhuku','label':'枠複'},
        {'key':'wakuren','value':'wakuren','label':'枠連'},
        {'key':'umaren','value':'umaren','label':'2連複'},
        {'key':'umatan','value':'umatan','label':'2連単'},
        {'key':'trio','value':'trio','label':'3連複'},
        {'key':'tierce','value':'tierce','label':'3連単'}
      ],
      sikiTexts: {
        "tanpuku": "単勝",
        "huku": "複勝",
        "wide": "ワイド",
        "wakuhuku": "枠複",
        "wakuren": "枠連",
        "umaren": "2連複",
        "umatan": "2連単",
        "trio": "3連複",
        "tierce": "3連単"
      },
      displaySikis: ['tanpuku', 'huku', 'wide', 'wakuhuku', 'wakuren', 'umaren', 'umatan', 'trio', 'tierce'],
      popSikis: [
        {'k':'wakuhuku','l':'枠複'},
        {'k':'wakuren','l':'枠連'},
        {'k':'wide','l':'ワイド'},
        {'k':'umaren', 'l':'2連複'},
        {'k':'umatan','l':'2連単'},
        {'k':'trio','l':'3連複'},
        {'k':'tierce','l':'3連単'}
      ],
      graphSiki: null,
      displayTimes: 24,
      timeTypes: [
        {'key':6,'value':6,'label':6},
        {'key':12,'value':12,'label':12},
        {'key':24,'value':24,'label':24},
      ],
      displayPops: 'all',
      timePops: [
        {'key':'all','value':'all','label':'全件'},
        {'key':1,'value':1,'label':1},
        {'key':3,'value':3,'label':3},
        {'key':5,'value':5,'label':5},
        {'key':10,'value':10,'label':10}
      ]
    },
    mounted : function() {
      var query = this.getQueryParams(window.location.search);
      this.race_kind = window.location.pathname.replace("/", "");
      if (!this.race_kind) {
        if (window.location.host === "kyoteiodds.com") {
          this.race_kind = "br";
        } else if (window.location.host === "keirinodds.com") {
          this.race_kind = "kr";
        }
      }
      this.race_date = query.race_date;
      this.race_track = query.race_track;
      if (query.race_horse) {
        this.race_horse = query.race_horse;
      }
      this.race_no = query.race_no;
      if (query.date_limit) {
        this.date_limit = Number(query.date_limit);
      }
      if (this.race_kind === undefined || this.race_kind == "undefined") {
        this.race_kind = null;
      }
      if (this.race_date === undefined || this.race_date == "undefined") {
        this.race_date = null;
      }
      if (this.race_track === undefined || this.race_track == "undefined") {
        this.race_track = null;
      }
      if (this.race_no === undefined || this.race_no == "undefined") {
        this.race_no = null;
      }
      if (this.race_horse === undefined || this.race_horse == "undefined") {
        this.race_horse = null;
      }

      if (store.get('displaySetting') === false) {
        this.displaySetting = store.get('displaySetting');
      }
      if (store.get('displaySections')) {
        this.displaySections = store.get('displaySections');
      }
      if (store.get('displayLines')) {
        this.displayLines = store.get('displayLines');
      }
      if (store.get('displaySikis')) {
        this.displaySikis = store.get('displaySikis');
      }
      if (Cookies.get('displayTimes')) {
        this.displayTimes = Cookies.get('displayTimes');
      }
      if (Cookies.get('displayPops')) {
        this.displayPops = Cookies.get('displayPops');
      }
      this.getItems(true);
    },
    computed: {
    },
    methods: {
      drawChart (t, i, traces, layout, options={}) {
        let gd, left, top;
        const WIDTH_IN_PERCENT_OF_PARENT = 100;
        gd = document.getElementById(`${t}_graph${i+1}`);
        if (!gd) {
          return;
        }
        gd.style.width = WIDTH_IN_PERCENT_OF_PARENT + '%';
        if (t == 'time') {
          gd.style.height = 500 + 'px';
        } else {
          gd.style.height = 400 + 'px';
        }
        gd.style.margin - (left = (100 - WIDTH_IN_PERCENT_OF_PARENT) / 2 + '%');
        gd.style.margin - (top = 0 + 'px');

        options.modeBarButtonsToRemove = ['hoverClosest3d', 'hoverClosestCartesian', 'hoverCompareCartesian'];
        options.showLink = false;
        options.displaylogo = false;

        options.displayModeBar = true;
        options.showSendToCloud = true;
        options.plotlyServerURL = "https://chart-studio.plotly.com";

        Plotly.newPlot(gd, traces, layout, options);
      },
      changeGraphSiki (s) {
        this.graphSiki = s;
        this.drawTimeChart();
      },
      drawTimeChart () {
        var layout = {
          font: { size: 10 },
          hovermode: "x unified",
          xaxis: {
            type: 'time',
            title: "オッズ時刻"
          },
          yaxis: {
            range: [0, 1.0],
            type: 'linear',
            title: "得票率"
          },
          margin: {
            t: 40,
            r: 60,
            b: 70,
            l: 60
          },
          legend: {
            orientation: "v",
            x: 1,
            y: 1,
            xanchor: "left",
            yanchor: "top",
            font:  {
              size: 8
            }

          }
        }

        let ri = 0;
        for (let race of this.race.race_info) {
          if (!this.graphSiki && race.odds_info && race.odds_info.sikis) {
            if (this.raceTypeIsBr()) {
              for (let i in race.odds_info.sikis) {
                if (race.odds_info.sikis[i] == "umatan") {
                  this.graphSiki = race.odds_info.sikis[i];
                  break;
                }
              }
            }
            if (!this.graphSiki) {
              for (let i in race.odds_info.sikis) {
                this.graphSiki = race.odds_info.sikis[i];
                break;
              }
            }
          }
          layout.title = `時系列得票率 (${this.sikiTexts[this.graphSiki]})`;
          let data = [];
          let allRates = [];
          let colors = [
            "#1f77b4", "#aec7e8", "#ff7f0e", "#ffbb78",
            "#2ca02c", "#98df8a", "#d62728", "#ff9896",
            "#9467bd", "#c5b0d5", "#8c564b", "#c49c94",
            "#e377c2", "#f7b6d2", "#7f7f7f", "#c7c7c7",
            "#bcbd22", "#dbdb8d", "#17becf", "#9edae5",
            "rgb(213, 238, 242)"
          ];

          if (race.horse_info && race.odds_info && race.odds_info.time_odds_times) {
            let all_times = [];
            let times = $.extend(true, [], race.odds_info.time_odds_times[this.graphSiki]);
            for (let t in times) {
              all_times.push(times[t]);
            }
            all_times = Array.from(new Set(all_times)).sort();
            all_times.reverse()
            let re_all_times = $.extend(true, [], all_times);
            re_all_times.reverse()

            for (let n in race.horse_info) {
              let info = race.horse_info[n];
              if (info.time_vote_rates && info.time_vote_rates[this.graphSiki]) {
                let rates = $.extend(true, [], info.time_vote_rates[this.graphSiki]);

                for (let r in rates) {
                  if (rates[r] < 0.95) {
                    allRates.push(rates[r]);
                  }
                  if (all_times.length <= r) {
                    break;
                  }
                }
                if (re_all_times.length > rates.length) {
                  for (let r = 0; re_all_times.length - rates.length; r++) {
                    rates.push(null);
                  }
                }
                rates.reverse()

                let trace = {
                  x: re_all_times,
                  y: rates,
                  type: 'scatter',
                  name: `${info.num} ${info.name}`,
                  textposition: 'top center',
                  cliponaxis: false,
                  line: {
                    width: 2,
                    shape: "linear",
                  },
                  marker: {
                    size: 6,
                    line: { width: 0 },
                    color: colors[n],
                  },
                  connectgaps: true,
                }
                data.push(trace);
              }
            }
          }

          layout.yaxis.range = [0, Math.max(...allRates) * 1.1];
          this.drawChart("time", ri, data, layout);
          ri++;
        }

      },
      drawScatterChart () {
        var layout = {
          font: { size: 10 },
          xaxis: {
            range: [0, 1],
            title: "時系列得票率 標準偏差"
          },
          yaxis: {
            range: [0, 1],
            title: "時系列得票率 平均"
          },
          legend: {
            yref: 'paper',
            itemsizing: "constant"
          },
          margin: {
            t: 20,
            r: 60,
            b: 80,
            l: 60
          }
        };

        let ri = 0;
        for (let race of this.race.race_info) {
          let siki_info = {};
          let means = [];
          let stds = [];
          if (race.odds_info) {
            for (let i in race.odds_info.sikis) {
              let s = race.odds_info.sikis[i];
              siki_info[s] = {"nums": [], "texts": [], "colors": [], "names": [], "jockeys": [], "odds": [], "rates": [], "means": [], "stds": []};
            }
          }
          if (race.horse_info) {
            for (let n in race.horse_info) {
              let info = race.horse_info[n];
              if (info.time_vote_means && info.time_vote_stds && Object.keys(info.time_vote_means).length == Object.keys(info.time_vote_stds).length) {
                for (let m in info.time_vote_means) {
                  if (siki_info[m]) {
                    let min_odds = info.time_min_odds[m] || [];
                    let vote_rates = info.time_vote_rates[m] || [];
                    let rank = info.time_vote_ranks[m] || [];
                    let jockey = info.jockey || "";
                    siki_info[m]["nums"].push(info.num);
                    siki_info[m]["texts"].push(`${info.num} ${info.name}<br>${jockey}<br>${this.sikiTexts[m]} 最新オッズ:${min_odds[0]} (${vote_rates[0]})<br>${rank[0]}番人気`);
                    siki_info[m]["rates"].push(vote_rates[0]);
                    siki_info[m]["colors"].push(info.color);
                    siki_info[m]["names"].push(info.name);
                    siki_info[m]["jockeys"].push(info.jockey);
                    siki_info[m]["means"].push(info.time_vote_means[m]);
                    means.push(info.time_vote_means[m]);
                  }
                }
                for (let m in info.time_vote_stds) {
                  if (siki_info[m]) {
                    siki_info[m]["stds"].push(info.time_vote_stds[m]);
                    stds.push(info.time_vote_stds[m]);
                  }
                }
              }
            }
          }

          var data = [];
          let has_data = false;

          for (let s in siki_info) {
            let si = siki_info[s];
            if (si && (si["stds"].length > 0 || si["means"].length > 0)) {
              has_data = true;
            }
            if (si) {
              let trace = {
                x: si["stds"],
                y: si["means"],
                mode: 'markers+text',
                type: 'scatter',
                name: this.sikiTexts[s],
                text: si["nums"],
                hovertext: si["texts"],
                textposition: 'top center',
                cliponaxis: false,
                marker: {
                  size: si["rates"],
                  sizeref: (Math.max.apply(null, si["rates"]) / (Math.sqrt(si["rates"].length) * 8 * 1)),
                  sizemin: 3
                }
              }
              data.push(trace);
            }
          }
          layout.xaxis.range = [0, Math.max(...stds) * 1.1];
          layout.yaxis.range = [0, Math.max(...means) * 1.1];
          if (has_data) {
            this.drawChart("vote", ri, data, layout);
          }
          ri++;
        }
      },

      getQueryParams(qs) {
        qs = qs.split('+').join(' ');
        var params = {},
            tokens,
            re = /[?&]?([^=]+)=([^&]*)/g;

        while (tokens = re.exec(qs)) {
            params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
        }
        return params;
      },
      changeDisplayPops(value) {
        Cookies.set('displayPops', value)
      },
      changeDisplayTimes(value) {
        Cookies.set('displayTimes', value)
      },
      toggleDisplaySetting() {
        this.displaySetting = !this.displaySetting
        store.set('displaySetting', this.displaySetting);
      },
      handleCheckAllSections(val) {
        if (val) {
          this.displaySections = [];
          for(let k in this.sectionTypes) {
            this.displaySections.push(this.sectionTypes[k].key);
          }
        } else {
          this.displaySections = [];
        }
        this.isSectionsIndeterminate = false;
        store.set('displaySections', this.displaySetions);
      },
      changeDisplaySections(value) {
        let checkedCount = value.length;
        this.checkSectionsAll = checkedCount === this.sectionTypes.length
        this.isSectionsIndeterminate = checkedCount > 0 && checkedCount < this.sectionTypes.length
        store.set('displaySections', value);
      },

      handleCheckAllLines(val) {
        if (val) {
          this.displayLines = [];
          for(let k in this.lineTypes) {
            this.displayLines.push(this.lineTypes[k].key);
          }
        } else {
          this.displayLines = [];
        }
        this.isLinesIndeterminate = false;
        store.set('displayLines', this.displayLines);
      },
      changeDisplayLines(value) {
        let checkedCount = value.length;
        this.checkLinesAll = checkedCount === this.lineTypes.length
        this.isLinesIndeterminate = checkedCount > 0 && checkedCount < this.lineTypes.length
        store.set('displayLines', value);
      },

      handleCheckAllSikis(val) {
        if (val) {
          this.displaySikis = [];
          for(let k in this.sikiTypes) {
            this.displaySikis.push(this.sikiTypes[k].key);
          }
        } else {
          this.displaySikis = [];
        }
        this.isSikisIndeterminate = false;
        store.set('displaySikis', this.displaySikis);
      },
      changeDisplaySikis(value) {
        let checkedCount = value.length;
        this.checkSikisAll = checkedCount === this.sikiTypes.length
        this.isSikisIndeterminate = checkedCount > 0 && checkedCount < this.sikiTypes.length
        store.set('displaySikis', value);
      },
      oddsColor(siki, odds) {
        switch (siki){
          case 'tanpuku':
          case 'huku':
          case 'wide':
            if (odds < 10) {
              return 'red';
            }
            break;
          case 'wakuren':
          case 'wakuhuku':
          case 'umaren':
          case 'umatan':
          case 'trio':
            if (odds < 20) {
              return 'red';
            }
            break;
          case 'tierce':
            if (odds < 100) {
              return 'red';
            }
            break;
          default:
        }
      },
      changeTimePopOdds(t) {
        this.timePopOddsIdx = t;
        this.sortPops();
      },
      heatMapColorforValue(t, values) {
        var value = 0.0;
        if (t < values.length - 2) {
          value = values[t] - values[t+1]
        } else {
          return "#fff";
        }

        if (value < 0.0) {
          value = value * 0.8
        } else if (value < 0.3) {
          value = value * 1.5
        } else if (value < 0.5) {
          value = value * 1.2
        } else if (value < 0.8) {
          value = value * 1.1
        } else if (value < 0.9) {
          value = value * 1.0
        }
        var h = (2.0 - value) * 240
        return "hsla(" + h + ", 100%, 50%, 0.5)";
      },
      dansoClass(rows, i) {
        if ((i < rows.length - 1) && rows[i+1][1] / rows[i][1] > 1.8) {
          return "danso";
        } else {
          return "";
        }
      },
      getSortedHash(inputHash) {
        var pairs = Object.entries(inputHash);
        pairs = pairs.sort(function(p1, p2){
          var p1Val = p1[1], p2Val = p2[1];
          return p1Val - p2Val;
        })
        return pairs;
      },
      sortPops() {
        this.sortedPops = []
        for (let race of this.race.race_info) {
          if (race.odds_info && race.odds_info.time_pops) {
            if (race.odds_info.time_pops.tanpuku) {
              var ss = this.getSortedHash(race.odds_info.time_pops.tanpuku[this.timePopOddsIdx]);
              this.sortedPops.push(ss);
            } else if (race.odds_info.time_pops.wide) {
              var ss = this.getSortedHash(race.odds_info.time_pops.wide[this.timePopOddsIdx]);
              this.sortedPops.push(ss);
            }
          }
        }
      },
      sortPopsIdx(i, j) {
        var n = 0;
        for (let pops of this.sortedPops[i]) {
          if (pops[0] == j) {
            return n;
          }
          n++;
        }
      },
      plusDate() {
        this.date_limit += 4;
        this.getItems();
      },
      gptPath (race) {
        const race_type = race.race_type.toLowerCase();
        return `/${race_type}/chatgpt?track=${race.race_info.track}&race=${race.race_info.race}&date=${race.race_info.race_date}`;
      },
      jra_date_path(date) {
        var url = '/jra?race_date=' + date;
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      jra_track_path(track) {
        var url = '/jra?race_track=' + track;
        if (this.jra_race_date != null && this.jra_race_date != undefined) {
          url += '&race_date=' + this.jra_race_date
        }
        return url;
      },
      jra_race_path(jra_race) {
        var url = '/jra?race_no=' + jra_race;
        if (this.jra_race_date != null && this.jra_race_date != undefined) {
          url += '&race_date=' + this.jra_race_date
        }
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      jra_race_track_path(jra_race, track) {
        var url = '/jra?race_no=' + jra_race;
        if (this.jra_race_date != null && this.jra_race_date != undefined) {
          url += '&race_date=' + this.jra_race_date
        }
        url += '&race_track=' + track
        return url;
      },
      jra_horse_path(jra_horse) {
        var url = '/jra?race_horse=' + jra_horse;
        return url;
      },
      nar_date_path(date) {
        var url = '/nar?race_date=' + date;
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      nar_track_path(track) {
        var url = '/nar?race_track=' + track;
        if (this.nar_race_date != null && this.nar_race_date != undefined) {
          url += '&race_date=' + this.nar_race_date
        }
        return url;
      },
      nar_race_path(nar_race) {
        var url = '/nar?race_no=' + nar_race;
        if (this.nar_race_date != null && this.nar_race_date != undefined) {
          url += '&race_date=' + this.nar_race_date
        }
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      nar_race_track_path(nar_race, track) {
        var url = '/nar?race_no=' + nar_race;
        if (this.nar_race_date != null && this.nar_race_date != undefined) {
          url += '&race_date=' + this.nar_race_date
        }
        url += '&race_track=' + track
        return url;
      },
      nar_horse_path(nar_horse) {
        var url = '/nar?race_horse=' + nar_horse;
        return url;
      },

      br_date_path(date) {
        var url = '/br?race_date=' + date;
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      br_track_path(track) {
        var url = '/br?race_track=' + track;
        if (this.br_race_date != null && this.br_race_date != undefined) {
          url += '&race_date=' + this.br_race_date
        }
        return url;
      },
      br_race_path(br_race) {
        var url = '/br?race_no=' + br_race;
        if (this.br_race_date != null && this.br_race_date != undefined) {
          url += '&race_date=' + this.br_race_date
        }
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      br_race_track_path(br_race, track) {
        var url = '/br?race_no=' + br_race;
        if (this.br_race_date != null && this.br_race_date != undefined) {
          url += '&race_date=' + this.br_race_date
        }
        url += '&race_track=' + track
        return url;
      },
      br_horse_path(br_horse) {
        var url = '/br?race_horse=' + br_horse;
        return url;
      },

      kr_date_path(date) {
        var url = '/kr?race_date=' + date;
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      kr_track_path(track) {
        var url = '/kr?race_track=' + track;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        return url;
      },
      kr_race_path(kr_race) {
        var url = '/kr?race_no=' + kr_race;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      kr_race_track_path(kr_race, track) {
        var url = '/kr?race_no=' + kr_race;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        url += '&race_track=' + track
        return url;
      },
      kr_horse_path(kr_horse) {
        var url = '/kr?race_horse=' + kr_horse;
        return url;
      },

      kr_date_path(date) {
        var url = '/kr?race_date=' + date;
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      kr_track_path(track) {
        var url = '/kr?race_track=' + track;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        return url;
      },
      kr_race_path(kr_race) {
        var url = '/kr?race_no=' + kr_race;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      kr_race_track_path(kr_race, track) {
        var url = '/kr?race_no=' + kr_race;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        url += '&race_track=' + track
        return url;
      },
      kr_horse_path(kr_horse) {
        var url = '/kr?race_horse=' + kr_horse;
        return url;
      },

      kr_date_path(date) {
        var url = '/kr?race_date=' + date;
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      kr_track_path(track) {
        var url = '/kr?race_track=' + track;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        return url;
      },
      kr_race_path(kr_race) {
        var url = '/kr?race_no=' + kr_race;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        if (this.race_track != null && this.race_track != undefined) {
          url += '&race_track=' + this.race_track
        }
        return url;
      },
      kr_race_track_path(kr_race, track) {
        var url = '/kr?race_no=' + kr_race;
        if (this.kr_race_date != null && this.kr_race_date != undefined) {
          url += '&race_date=' + this.kr_race_date
        }
        url += '&race_track=' + track
        return url;
      },
      kr_horse_path(kr_horse) {
        var url = '/kr?race_horse=' + kr_horse;
        return url;
      },

      raceTypeIsJra() {
        return this.race_type && this.race_type.indexOf('JRA') >= 0
      },
      raceTypeIsNar() {
        return this.race_type && this.race_type.indexOf('NAR') >= 0
      },
      raceTypeIsBr() {
        return this.race_type && this.race_type.indexOf('BR') >= 0
      },
      raceTypeIsKr() {
        return this.race_type && this.race_type.indexOf('KR') >= 0
      },
      raceIsEnd() {
        if (this.raceTypeIsJra()) {
          return this.jra_races.length > 0 && Number(this.race_no) === Number(this.jra_races[this.jra_races.length-1]);
        } else if (this.raceTypeIsNar()) {
          return this.nar_races.length > 0 && Number(this.race_no) === Number(this.nar_races[this.nar_races.length-1]);
        } else if (this.raceTypeIsBr()) {
          return this.br_races.length > 0 && Number(this.race_no) === Number(this.nar_races[this.br_races.length-1]);
        } else if (this.raceTypeIsKr()) {
          return this.kr_races.length > 0 && Number(this.race_no) === Number(this.nar_races[this.kr_races.length-1]);
        } else {
          return true;
        }
      },
      targetHorsePath(horse) {
        if (this.raceTypeIsJra()) {
          return this.jra_horse_path(horse);
        } else if (this.raceTypeIsNar()) {
          return this.nar_horse_path(horse);
        } else if (this.raceTypeIsBr()) {
          return this.br_horse_path(horse);
        } else if (this.raceTypeIsKr()) {
          return this.kr_horse_path(horse);
        }
        return "";
      },
      targetRacePath(track, race_next) {
        if (this.raceTypeIsJra()) {
          return this.jra_race_track_path(race_next, track);
        } else if (this.raceTypeIsNar()) {
          return this.nar_race_track_path(race_next, track);
        } else if (this.raceTypeIsBr()) {
          return this.br_race_track_path(race_next, track);
        } else if (this.raceTypeIsKr()) {
          return this.kr_race_track_path(race_next, track);
        }
        return "";
      },
      prevRacePath() {
        var race_next = (Number(this.race_no) - 1).toString();
        if (this.raceTypeIsJra()) {
          return this.jra_race_path(race_next);
        } else if (this.raceTypeIsNar()) {
          return this.nar_race_path(race_next);
        } else if (this.raceTypeIsBr()) {
          return this.br_race_path(race_next);
        } else if (this.raceTypeIsKr()) {
          return this.kr_race_path(race_next);
        }
        return "";
      },
      nextRacePath() {
        var race_next = (Number(this.race_no) + 1).toString();
        if (this.raceTypeIsJra()) {
          return this.jra_race_path(race_next);
        } else if (this.raceTypeIsNar()) {
          return this.nar_race_path(race_next);
        } else if (this.raceTypeIsBr()) {
          return this.br_race_path(race_next);
        } else if (this.raceTypeIsKr()) {
          return this.kr_race_path(race_next);
        }
        return "";
      },
      removeRaceHorse() {
        this.race_horse = null;
        this.getItems(true);
      },
      prevItems() {
        this.page -= 1;
        this.getItems(true);
      },
      changeItems() {
        this.page += 1;
        this.getItems(true);
      },
      getItemsAfterTime(time) {
        this.time_limit = time;
        this.getItems(false);
      },
      async getItems(goTop) {
        let url = '/odds?' + 'page=' + this.page.toString() + '&race_kind=' + this.race_kind + '&race_date=' + this.race_date + '&race_track=' + this.race_track + '&race_no=' + this.race_no;
        if (this.date_limit != null) {
          url += '&date_limit=' + this.date_limit.toString();
        }
        if (this.race_horse != null) {
          url += '&race_horse=' + this.race_horse;
        }
        if (this.time_limit != null) {
          url += '&time=' + this.time_limit;
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0)'
        });
        await this.$axios.get(url).then(
          (response) => {
            loading.close();

            this.error = null;
            var ele = document.getElementsByClassName("rate_diff");
            for (var i = 0; i < ele.length; i++) {
                ele[i].removeAttribute('style');
            }
            if (response.data.race_type && response.data.race_type.indexOf("JRA") >= 0) {
              this.race = response.data.jra_info;
            } else if (response.data.race_type && response.data.race_type.indexOf("NAR") >= 0) {
              this.race = response.data.nar_info;
            } else if (response.data.race_type && response.data.race_type.indexOf("BR") >= 0) {
              this.race = response.data.br_info;
            } else if (response.data.race_type && response.data.race_type.indexOf("KR") >= 0) {
              this.race = response.data.kr_info;
            }
            this.sortPops()
            this.total_count = response.data.total_count
            if (response.data.date_limit) {
              this.date_limit = Number(response.data.date_limit)
            }
            this.race_kind = response.data.race_kind
            this.race_type = response.data.race_type
            this.race_date = response.data.race_date
            this.race_track = response.data.race_track
            if (response.data.race_horse) {
              this.race_horse = response.data.race_horse
            }
            this.race_no = response.data.race_no
            if (this.race_kind == "null") {
              this.race_kind = null;
            }
            if (this.race_type == "null") {
              this.race_type = null;
            }
            if (this.race_date == "null") {
              this.race_date = null;
            }
            if (this.race_track == "null") {
              this.race_track = null;
            }
            if (this.race_no == "null") {
              this.race_no = null;
            }
            this.jra_races = response.data.jra_info.races
            this.nar_races = response.data.nar_info.races

            this.jra_race_date = response.data.jra_info.race_date
            this.jra_date_info = response.data.jra_info.date_info
            this.jra_track_info =  response.data.jra_info.track_info

            this.nar_race_date = response.data.nar_info.race_date
            this.nar_date_info = response.data.nar_info.date_info
            this.nar_track_info =  response.data.nar_info.track_info

            this.br_races = response.data.br_info.races
            this.br_race_date = response.data.br_info.race_date
            this.br_date_info = response.data.br_info.date_info
            this.br_track_info =  response.data.br_info.track_info

            this.kr_races = response.data.kr_info.races
            this.kr_race_date = response.data.kr_info.race_date
            this.kr_date_info = response.data.kr_info.date_info
            this.kr_track_info =  response.data.kr_info.track_info
            if (goTop != false) {
              $('html, body').animate({scrollTop: $('body').offset().top - 50}, 0)
            }

            this.$nextTick(function() {
              this.drawScatterChart();
              this.drawTimeChart();

              let jid = "loadedjs-regimag";
              let reg_url = "https://regimag.jp/gadget/product_parts";
              let sc = document.getElementById(jid);
              if (sc) {
                return;
              }
              sc = document.createElement('script');
              sc.async = true;
              sc.setAttribute('src', reg_url);
              sc.setAttribute('id', jid);
              sc.setAttribute('charset', "utf-8");
              document.body.appendChild(sc);

              let adsbygoogle = window.adsbygoogle || [];
              for (let i = 0; i < 10; i++) { adsbygoogle.push({}); }
            });
          },
          (error) => {
            loading.close();
            this.error = error.response.statusText;
            if (error.response.data.message) {
            } else {
            }
          }
        )
      }
    },
    components: {
      "sample": Sample
    }
  })
})
